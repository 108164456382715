import {
  FynbosIcon,
  Icon,
  IntlMoney,
  OutlineButtonRouter,
  Router,
  TextField
} from '@/Components'
import { MarketingLayout } from '@/Layouts'
import { cn } from '@/Lib'
import * as Slider from '@radix-ui/react-slider'
import { motion, useScroll, useTransform } from 'framer-motion'
import { ReactNode, useMemo, useState } from 'react'

Page.layout = (page: ReactNode) => <MarketingLayout>{page}</MarketingLayout>

export default function Page() {
  return (
    <div className='w-full'>
      <Hero />
      <main className='relative w-full'>
        <BadAdvice />
        <Parents />
        <FynbosMethod />
        <EmergencySavings />
        <TaxFree />
        <PlatformFeatures />
        <Router
          href='/signup'
          className='group sticky bottom-0 flex w-full items-center rounded-none bg-purple-400 lg:p-6'
        >
          <div className='mx-auto flex max-h-max w-full max-w-5xl items-center justify-between p-4'>
            <span className='text-4xl font-semibold uppercase leading-none text-strong lg:text-7xl'>
              Let&apos;s go
            </span>
            <div className='flex items-center justify-center rounded-full bg-slate-900 p-4 transition-all group-hover:scale-110 lg:p-10'>
              <Icon large className='text-white'>
                arrow_right_alt
              </Icon>
            </div>
          </div>
        </Router>
      </main>
    </div>
  )
}

function Hero() {
  const { scrollY } = useScroll()

  const rotation = useTransform(scrollY, (v) => -v / 2)
  const x = useTransform(scrollY, (v) => v / 2)

  return (
    <section className='mx-auto flex min-h-[calc(100svh-120px)] w-full max-w-5xl flex-col justify-center gap-6 overflow-x-hidden p-4 md:min-h-[calc(100dvh-140px)] lg:min-h-[calc(100dvh-200px)]'>
      <h1
        className={cn(
          'flex flex-col font-black uppercase leading-none text-strong',
          'text-[4rem] leading-[3.5rem]',
          'sm:text-[8rem] sm:leading-[7rem]',
          'lg:text-[10rem] lg:leading-[9rem]'
        )}
      >
        <span>Just</span>
        <div className='flex shrink items-center gap-0.5 sm:gap-1'>
          <span>F</span>
          <motion.svg
            style={{
              rotate: rotation
            }}
            className='size-[3rem] flex-none sm:size-[5.8rem] lg:size-[7rem]'
            // width='122'
            // height='120'
            viewBox='0 0 122 120'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M44.3851 119.814L47.7082 83.261L17.3856 104.445L0.770508 75.3689L34.0007 59.9999L0.770508 44.6309L17.3856 15.5545L47.7082 36.7388L44.3851 0.185547H77.6153L74.2923 36.7388L104.615 15.5545L121.23 44.6309L87.9998 59.9999L121.23 75.3689L104.615 104.445L74.2923 83.261L77.6153 119.814H44.3851Z'
              fill='#0F172A'
            />
          </motion.svg>
          <span>cking</span>
        </div>

        <motion.span initial={{ x: 0 }} style={{ x: x }}>
          start
        </motion.span>
      </h1>
      <p className='max-w-prose text-xl'>
        The secret to long term investing, is not a secret. It&apos;s low fees,
        low tax, and lots and lots of time. Stop procrastinating. Stop worrying.
        Start investing. We&apos;ll show you how.
      </p>
    </section>
  )
}

function BadAdvice() {
  return (
    <section className='p-4 pb-20 lg:pt-20'>
      <div className='mx-auto flex w-full max-w-5xl flex-col justify-center gap-6 text-pretty'>
        <h2 className='text-[3rem] font-black uppercase leading-[2.5rem] text-strong line-through decoration-red-500 sm:text-[4rem] sm:leading-[3.5rem] sm:decoration-[16px] lg:text-[6.25rem] lg:leading-[5.5rem]'>
          bad advice
        </h2>
        <h2 className='text-[3rem] font-black uppercase leading-[2.5rem] text-strong line-through decoration-red-500 sm:text-[4rem] sm:leading-[3.5rem] sm:decoration-[16px] lg:text-[6.25rem] lg:leading-[5.5rem]'>
          Predatory fees
        </h2>
        <h2 className='text-[3rem] font-black uppercase leading-[2.5rem] text-strong line-through decoration-red-500 sm:text-[4rem] sm:leading-[3.5rem] sm:decoration-[16px] lg:text-[6.25rem] lg:leading-[5.5rem]'>
          Fear
        </h2>
        <p className='max-w-prose text-xl'>
          We&apos;re tired of watching young professionals squander their future
          wealth due to bad advice, predatory fees, and fear of making a
          mistake.
        </p>
      </div>
    </section>
  )
}

function Parents() {
  return (
    <section className='bg-blue-600 p-4 py-20'>
      <div className='mx-auto flex w-full max-w-5xl flex-col justify-center gap-6 text-pretty text-center'>
        <h3 className='flex flex-col text-5xl font-bold uppercase leading-10 text-inverted'>
          We&apos;ve built the platform your parents wish they&apos;d had
        </h3>
        <p className='mx-auto max-w-prose text-2xl text-inverted'>
          How you invest at the start of your career is critical to your long
          term wealth. Invest a small amount now, or much much much more later.
          But, you also need to avoid unnecessary fees and taxes to make every
          cent count.
        </p>
        <p className='mx-auto max-w-prose text-2xl text-inverted'>
          Relax. It’s easy to get started on the right track. We’ll show you
          how.
        </p>
      </div>
    </section>
  )
}

function FynbosMethod() {
  return (
    <section className='p-4 py-40'>
      <div className='mx-auto flex w-full max-w-5xl flex-col justify-center gap-10 text-pretty'>
        <FynbosIcon className='h-40' />
        <h3
          className={cn(
            'flex flex-col text-center font-black uppercase text-strong',
            'text-[4rem] leading-[3.5rem]',
            'sm:text-[8rem] sm:leading-[7rem]',
            'lg:text-[10rem] lg:leading-[9rem]'
          )}
        >
          <span>fynbos</span>
          <span>method</span>
        </h3>

        <div className='grid w-full gap-x-6 gap-y-6 lg:grid-cols-3'>
          <Icon large className='mt-6 lg:order-1'>
            start
          </Icon>
          <h4 className='text-2xl font-medium lg:order-4'>
            Just f*cking start
          </h4>
          <p className='lg:order-7'>
            Rule # 1 of long term investing is to start. There is no rule # 2.
            No matter how much you have to invest, the greatest impact you will
            have on your returns is starting early.
          </p>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            height='48px'
            viewBox='0 -960 960 960'
            width='48px'
            className='mt-6 fill-current lg:order-2'
          >
            <path d='M480-96 216-360l51-51 177 177v-294h72v294l177-177 51 51L480-96Zm-36-504v-120h72v120h-72Zm0-192v-72h72v72h-72Z' />
          </svg>

          <h4 className='text-2xl font-medium lg:order-5'>
            Aggressively pursue the lowest fees
          </h4>
          <p className='lg:order-8'>
            Percentage based fees are like parasites on your money. Never pay a
            percentage-based adviser fee and seek out the investments that
            charge the lowest management fees.
          </p>
          <Icon large className='mt-6 lg:order-3'>
            maximize
          </Icon>
          <h4 className='text-2xl font-medium lg:order-6'>
            Max out your TFSA ASAP
          </h4>
          <p className='lg:order-9'>
            The unsung hero of the South African investor. There is no reason to
            invest anywhere else until you&apos;ve maxed out your TFSA
            contributions. Over decades it is impossible to beat the value of
            tax-free returns.
          </p>
        </div>
      </div>
    </section>
  )
}

function EmergencySavings() {
  const [amount, setAmount] = useState<number>(20000)

  const total = useMemo(() => amount * 3, [amount])
  return (
    <section
      id='emergency-savings'
      className='scroll-mt-24 bg-green-400 p-4 py-20'
    >
      <div className='mx-auto flex w-full max-w-5xl flex-col justify-center gap-10 text-pretty'>
        <div className='mx-auto flex'>
          <div className='h-20 w-10 rounded-bl-full rounded-tr-full bg-green-600 sm:h-40 sm:w-20'></div>
          <div className='h-20 w-10 rounded-br-full rounded-tl-full bg-green-300 sm:h-40 sm:w-20'></div>
        </div>
        <h3
          className={cn(
            'flex flex-col text-center font-black uppercase text-strong',
            'text-[3rem] leading-[2.5rem]',
            'sm:text-[6rem] sm:leading-[5rem]',
            'lg:text-[10rem] lg:leading-[9rem]'
          )}
        >
          <span>emergency</span>
          <span>savings</span>
        </h3>
        <p className='mx-auto max-w-prose text-center text-xl'>
          Life can be unpredictable. When you need some money urgently the last
          thing you should do is sell your long term investments or take on
          debt. Having an emergency savings fund is a critical buffer that gives
          you peace of mind.
        </p>
        <div
          id='emergency-savings-tool'
          className='flex w-full scroll-mt-24 flex-col overflow-hidden rounded-[1.25rem] lg:flex-row'
        >
          <div className='flex w-full flex-col gap-4 bg-white p-10'>
            <h5 className='text-lg font-medium'>
              How much do you spend each month?
            </h5>
            <p>
              Add up any unavoidable monthly expenses that you&apos;d still have
              to pay if you had no income.
            </p>
            <TextField
              prefix='R'
              type='number'
              value={amount}
              onChange={(e) => setAmount(Number(e.target.value))}
            />
          </div>
          <div className='flex w-full flex-col gap-4 bg-green-950 p-10 text-white'>
            <h5 className='text-lg font-medium'>
              Your emergency savings target amount
            </h5>
            <div className='flex w-full justify-center rounded-xl bg-green-700 px-4 py-3 text-2xl font-medium'>
              <IntlMoney value={total} maximumFractionDigits={0} />
            </div>
            <p>
              This amount should be enough to cover you for 3 months expenses.
            </p>
          </div>
        </div>
        <div className='flex w-full flex-col gap-2 rounded-[1.25rem] bg-green-300 p-10 text-green-800'>
          <h5 className='text-2xl font-medium'>Emergency savings tool</h5>
          <div className='flex flex-col gap-4 sm:flex-row sm:items-center'>
            <p className='text-xl'>
              Fynbos helps you understand, set up, and optimise your emergency
              savings fund so you can be confident that your long term
              investments are protected from shocks.
            </p>
            <OutlineButtonRouter
              href={route('emergency-savings.index')}
              shrink
              className='min-w-56 border-green-800 text-green-800 hover:bg-green-400 focus-visible:outline-blue-500'
            >
              Get started
            </OutlineButtonRouter>
          </div>
        </div>
      </div>
    </section>
  )
}

function TaxFree() {
  const [age, setAge] = useState<number[]>([18])

  const INTEREST_RATE = 0.094 // 9.4%
  const MAX_YEAR = 65
  const MIN_YEAR = 18

  const total = useMemo(() => {
    return calculateTFSAFutureValue(3000, INTEREST_RATE, MAX_YEAR - MIN_YEAR)
  }, [])

  const ageTotal = useMemo(() => {
    return calculateTFSAFutureValue(3000, INTEREST_RATE, MAX_YEAR - age[0])
  }, [age])

  const percentage = useMemo(() => {
    return (ageTotal / total) * 100
  }, [total, ageTotal])

  return (
    <section
      id='tax-free-investing'
      className='scroll-mt-24 bg-sky-400 p-4 py-20'
    >
      <div className='mx-auto flex w-full max-w-5xl flex-col justify-center gap-10 text-pretty'>
        <div className='mx-auto flex'>
          <div className='flex flex-col'>
            <div className='size-10 rounded-full rounded-tr-none bg-sky-500 sm:size-20'></div>
            <div className='size-10 rounded-full rounded-tl-none bg-sky-600 sm:size-20'></div>
          </div>
          <div className='flex flex-col'>
            <div className='size-10 rounded-full rounded-br-none bg-sky-200 sm:size-20'></div>
            <div className='size-10 rounded-full rounded-bl-none bg-sky-300 sm:size-20'></div>
          </div>
        </div>
        <h3
          className={cn(
            'flex flex-col text-center font-black uppercase text-strong',
            'text-[3rem] leading-[2.5rem]',
            'sm:text-[6rem] sm:leading-[5rem]',
            'lg:text-[10rem] lg:leading-[9rem]'
          )}
        >
          <span>tax-free</span>
          <span>investing</span>
        </h3>
        <p className='mx-auto max-w-prose text-center text-xl'>
          A Tax Free Savings Account (TFSA) is a special account where you can
          invest without paying taxes on your earnings. For long term investors
          it is more powerful than a retirement annuity or pension if you use it
          correctly.
        </p>
        <div
          id='tax-free-investing-tool'
          className='flex w-full scroll-mt-24 flex-col justify-center gap-2 rounded-[1.25rem] bg-sky-950 p-10 text-center text-white'
        >
          <div className='mx-auto flex w-full max-w-xl flex-col justify-center gap-6'>
            <h5 className='text-xl sm:text-3xl'>
              How much will you have at <span className='font-medium'>65</span>{' '}
              if you <span className='font-medium'>maximise your TFSA?</span>
            </h5>
            <span className='text-4xl font-medium text-sky-400 sm:text-7xl'>
              <IntlMoney value={ageTotal} maximumFractionDigits={0} />
            </span>
            <div className='flex w-full flex-col items-end'>
              <span className='text-xs'>
                <IntlMoney value={total} maximumFractionDigits={0} />
              </span>
              <div className='mb-1 mr-2 h-2 w-px bg-white' />
              <div className='flex h-6 w-full overflow-hidden rounded bg-rose-500'>
                <div
                  style={{ width: `${percentage}%` }}
                  className={cn('h-full bg-sky-400')}
                />
              </div>
            </div>
            <Slider.Root
              className='relative flex h-5 w-full touch-none select-none items-center'
              value={age}
              onValueChange={(age) => setAge(age)}
              max={MAX_YEAR}
              min={MIN_YEAR}
              step={1}
            >
              <Slider.Track className='relative h-2 grow rounded-full bg-slate-600'>
                <Slider.Range className='absolute h-full rounded-full bg-slate-700' />
              </Slider.Track>
              <Slider.Thumb asChild aria-label='Age'>
                <div className='flex rounded bg-white px-6 py-1 text-xl font-medium text-medium focus:outline-none sm:text-2xl'>
                  <span>{age[0]}</span>
                </div>
              </Slider.Thumb>
            </Slider.Root>

            <p className='text-xl'>The age you start investing.</p>
            <p className='text-sm'>
              Assumes a <IntlMoney value={3000} maximumFractionDigits={0} />{' '}
              monthly investment up to the maximum lifetime contribution of{' '}
              <IntlMoney value={500000} maximumFractionDigits={0} /> and a{' '}
              {INTEREST_RATE * 100}% annual return, equal to the MSCI World
              index over the last 40 years.
            </p>
          </div>
        </div>
        <div className='flex w-full flex-col gap-2 rounded-[1.25rem] bg-sky-300 p-10 text-sky-800'>
          <h5 className='text-2xl font-medium'>Tax-free investing tool</h5>
          <div className='flex flex-col gap-4 sm:flex-row sm:items-center'>
            <p className='text-xl'>
              Fynbos helps you understand, set up, and optimise your TFSA so you
              can relax, knowing you&apos;ve got your long term investing off to
              a great start.
            </p>
            <OutlineButtonRouter
              href={route('tfsa.index')}
              shrink
              className='min-w-56 border-sky-800 text-sky-800 hover:bg-sky-400 focus-visible:outline-blue-500'
            >
              Get started
            </OutlineButtonRouter>
          </div>
        </div>
      </div>
    </section>
  )
}

function PlatformFeatures() {
  return (
    <section className='bg-page p-4 py-40'>
      <div className='mx-auto flex w-full max-w-5xl flex-col justify-center gap-10 text-pretty'>
        <h3
          className={cn(
            'flex flex-col text-center font-black uppercase text-strong',
            'text-[3rem] leading-[2.5rem]',
            'sm:text-[4rem] sm:leading-[3.5rem]'
          )}
        >
          <span>platform</span>
          <span>features</span>
        </h3>
        <div className='grid w-full gap-x-6 gap-y-10 lg:grid-cols-2'>
          <div className='flex flex-col gap-4'>
            <div className='flex items-center gap-2'>
              <Icon large>attach_money</Icon>
              <h4 className='text-2xl font-medium'>Debt</h4>
            </div>
            <p>
              Fynbos helps you to understand the difference between healthy and
              unhealthy debt so you can ensure you&apos;re using debt wisely and
              avoiding dangerous traps.
            </p>
          </div>
          <div className='flex flex-col gap-4'>
            <div className='flex items-center gap-2'>
              <Icon large>savings</Icon>
              <h4 className='text-2xl font-medium'>Emergency Savings</h4>
            </div>
            <p>
              Using our emergency savings module you&apos;ll create an emergency
              fund that protects your investments from any financial shocks.
            </p>
          </div>
          <div className='flex flex-col gap-4'>
            <div className='flex items-center gap-2'>
              <Icon large>finance</Icon>
              <h4 className='text-2xl font-medium'>Tax Free Investing</h4>
            </div>
            <p>
              Maximize the benefit of tax free returns for your long term wealth
              creation by taking advantage of the most poorly understood and
              underappreciated investment vehicle in South Africa, the TFSA.
            </p>
          </div>
          <div className='flex flex-col gap-4'>
            <div className='flex items-center gap-2'>
              <Icon large>target</Icon>
              <h4 className='text-2xl font-medium'>Goals</h4>
            </div>
            <p>
              At every step we&apos;re there to help you, understand your
              current context, evaluate your needs, and set goals to get to
              where you need to be. You can build simple habits that will have
              outsized returns in your future.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

function calculateTFSAFutureValue(
  monthlyContribution: number,
  annualInterestRate: number,
  years: number
): number {
  const maxTotalContribution = 500000
  const months = years * 12
  const monthlyInterestRate = annualInterestRate / 12

  let futureValue = 0
  let totalContribution = 0

  for (let month = 1; month <= months; month++) {
    // Add the monthly contribution if total contribution is below the maximum allowed
    if (totalContribution < maxTotalContribution) {
      totalContribution += monthlyContribution
      futureValue += monthlyContribution
    }

    // Apply the monthly interest rate
    futureValue *= 1 + monthlyInterestRate

    // Ensure total contribution does not exceed the max total contribution
    if (totalContribution > maxTotalContribution) {
      totalContribution = maxTotalContribution
    }
  }

  return futureValue
}
